/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
import Layout from '@/views/layout/index.vue'
export default [
    {
        name: '商机管理',
        path: '/business/allbusiness',
        perm: '',
        meta: {module: 'business'},
        component: Layout,
        children: [
          {
            name: '全部商机',
            path: '/business/allbusiness',
            perm: 'business.allbusiness.list',
            meta: {module: 'business'},
            show:false,
            component: () => import('@/views/business/allbusiness.vue'),
          },
          {
          name: '我的商机',
          path: '/business/mybusiness',
          perm: 'business.mybusiness.list',
          meta: {module: 'business'},
          show:false,
          component: () => import('@/views/business/mybusiness.vue'),
          },
          {
            name: '编辑商机',
            path: '/business/edit/:id',
            perm: 'business.business.edit',
            meta: {module: 'business'},
            show:false,
            component: () => import('@/views/business/edit.vue'),
          },
        ]
    }
]