export const accessoryApi = {
  // 初始化信息
  getInit: {
    method: 'post',
    api: 'backend/user/init',
  },
  getShopInit: {
      method: 'post',
      api: 'backend/setting/getShopInit',
  },
  setShopInit: {
    method: 'post',
    api: 'backend/setting/setShopInit',
  },
  uploadImg: {
    method: 'post',
    api: 'backend/init/uploadImg',
  },
  uploadVideo: {
    method: 'post',
    api: 'backend/init/uploadVideo',
  },
  uploadFile: {
    method: 'post',
    api: 'backend/init/uploadFile',
  },
  operationLogList: {
    method: 'post',
    api: 'backend/setting/operationLogList',
  },
  getOperationLog: {
    method: 'post',
    api: 'backend/setting/getOperationLog',
  },
  getSalesSpimpleData: {
    method: 'post',
    api: 'backend/setting/getSalesSpimpleData',
  },
  getBusinessSpimpleData: {
    method: 'post',
    api: 'backend/setting/getBusinessSpimpleData',
  },
  getClientsSpimpleData: {
    method: 'post',
    api: 'backend/setting/getClientsSpimpleData',
  },
}
