/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
import Layout from '@/views/layout/index.vue'
export default [
    {
        name: '产品管理',
        path: '/product/list',
        perm: '',
        meta: {module: 'product'},
        component: Layout,
        children: [
          {
            name: '产品列表',
            path: '/product/list',
            perm: 'product.product.list',
            meta: {module: 'product'},
            show:false,
            component: () => import('@/views/product/list.vue'),
          },
          {
            name: '产品分类',
            path: '/product/cates',
            perm: 'product.cates.list',
            meta: {module: 'product'},
            show:false,
            component: () => import('@/views/product/cates.vue'),
          }
        ]
    }
]