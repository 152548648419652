/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
 import Layout from '@/views/layout/index.vue'
 export default [
     {
         name: '统计1',
         path: "/",
         redirect: '/workplace/statistics',
     },
     {
         name: '数据统计',
         path: '/workplace/statistics',
         perm: '',
         meta: {module: 'home'},
         component: Layout,
         children: [
             {
                 name: '数据统计',
                 path: '/workplace/statistics',
                 perm: '',
                 meta: {module: 'home'},
                 show:false,
                 component: () => import('@/views/home/index/index.vue'),
             }
         ]
     }
 ]