export const companyApi = {
  save: {
      method: 'post',
      api: 'backend/company/save'
  },
  getList: {
    method: 'post',
    api: 'backend/company/list'
  },
  get: {
    method: 'post',
    api: 'backend/company/get'
  },
  del: {
    method: 'post',
    api: 'backend/company/del'
  },
}