import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { afterEach,beforeGuard } from './guard/index'
import permMapper from './permit/permMap'
import store from '@/store'

const paths = require.context('./', false, /\/.*\.ts$/)
let routes: Array<RouteRecordRaw> = [] //各个模块的路由配置集合
paths.keys()
      .map((item) => {
          //获取本级目录下的全部配置参数
          return paths(item).default
      })
      .filter((item) => item)
      .forEach((item) => {
          routes = routes.concat(item)
      })

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
permMapper.init(routes) //路由权限查询对象
store.commit('config/setRoutes', routes)
router.afterEach(afterEach)
router.beforeEach(async (to, from, next) => {
  try {
      /* 检测路径 */
      await beforeGuard.rediectPath(to, from, next)
      /* 调整路由参数 */
      await beforeGuard.parsePath(to, from, next)
      /* 检测登录状态 */
      await beforeGuard.checkLogin(to, from, next)
      /* 检测权限 */
      await beforeGuard.checkPerm({ to, from, next })
      
  } catch (error) {
      console.log(error)
  }
})
export default router
