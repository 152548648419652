export const returnmoneyApi = {
    list: {
      method: 'post',
      api: 'backend/Returnmoney/list'
    },
    save_return: {
        method: 'post',
        api: 'backend/Returnmoney/save_return'
    },
    save_bill: {
        method: 'post',
        api: 'backend/Returnmoney/save_bill'
    },
  }