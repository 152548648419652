
import {
    mapState,
    mapGetters
} from 'vuex'

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapState('layout', {
        routerEntering: (state: any) => state.routerEntering,
    }),
    ...mapState('config', {
        user: (state: any) => state.user,
    }),
    ...mapState({
        requestInfo: (state: any) => state.requestInfo
    }),
    ...mapGetters({
        getRequestInfo: 'getRequestInfo'
    }),
    ...mapState('account', {
        shopId: (state: any) => state.shop.shopId,
    }),
  },
}
