import Layout from '@/views/alone/components/Layout.vue'

export default [{
    name: '登录',
    path: '/account/login',
    component: Layout,
    meta: {module: 'account'},
    children: [
        {
            name: '登录',
            path: '/account/login',
            meta: {module: 'account'},
            perm: '',
            component: () => import('@/views/alone/login/index.vue')
        }
    ]
}]