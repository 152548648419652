import { menuTraverser } from '../assets/js/traverser'
let paths = require.context('./', false, /^((?!Plugin).)*\.ts$/);//获取当前全部js文件
let menus:any = {};//各个模块的路由配置集合
let pageIndex = 0

paths.keys().forEach((item:any) => {
    let def = paths(item).default;
    menuTraverser(def, (res:any) => {
        if (typeof res == 'object' && !res._pageId) {
            res._pageId = '_pageId' + new Date().getTime() + '_' + pageIndex++
        }
    })
    if (def) {
        let menuname = item.replace(/(\.\/)|(\.ts)/g,'')
        menus[menuname]=def
    }
});

export default menus