
import { defineComponent } from 'vue';
import NavBar from '../layout/components/NavBar.vue';
import SubMenu from '../layout/components/SubMenu.vue';

export default defineComponent({
  name: 'Home',
  components: {
    NavBar,
    SubMenu
  },
});
