export default {
  title: '系统权限',
  name: 'upms',
  path: '/upms/admin',
  show: true,
  icon: 'icon-zuocedaohang-shouye1',
  perm: '',
  zIndex: 1,
  children: {
    company: {
      title: '公司管理',
      name: 'department',
      path: '/upms/company',
      icon: "icon-zuocedaohang-shouye1",
      perm:'upms.company',
      show: true
    },
    department: {
      title: '部门管理',
      name: 'department',
      path: '/upms/department',
      icon: "icon-zuocedaohang-shouye1",
      perm:'upms.department',
      show: true
    },
    admin: {
        title: '用户管理',
        name: 'admin',
        path: '/upms/admin',
        icon: "icon-zuocedaohang-shouye1",
        perm:'upms.admin',
        show: true
    },
    
    role: {
      title: '角色管理',
      name: 'role',
      path: '/upms/role',
      icon: "icon-zuocedaohang-shouye1",
      perm:'upms.role',
      show: true,
      children: {
        add: {
          title: '添加角色',
          name: 'addrole',
          path: '/upms/role/add',
          show: false,
          perm:'upms.role.add',
        },
        edit: {
          title: '编辑角色',
          name: 'editrole',
          path: '/upms/role/edit/:id',
          show: false,
          perm:'upms.role.edit',
        },
      }
    }
  }
}
