export default {
  title: '安全设置',
  name: 'website',
  path: '/website/operationLog',
  show: true,
  icon: 'icon-zuocedaohang-shouye1',
  perm: '',
  zIndex: 10,
  children: {
      operationLog: {
        title: '操作日志',
        name: 'operationLog',
        path: '/website/operationLog',
        icon: "icon-zuocedaohang-shouye1",
        perm:'website.operationLog',
        show: true
      }
  }
}
