/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
import Layout from '@/views/layout/index.vue'
export default [
    {
        name: '合同管理',
        path: '/agreement/allagreement',
        perm: '',
        meta: {module: 'agreement'},
        component: Layout,
        children: [
          {
            name: '全部合同',
            path: '/agreement/allagreement',
            perm: 'agreement.allagreement.list',
            meta: {module: 'agreement'},
            show:false,
            component: () => import('@/views/agreement/allagreement.vue'),
          },
          {
          name: '我的合同',
          path: '/agreement/myagreement',
          perm: 'agreement.myagreement.list',
          meta: {module: 'agreement'},
          show:false,
          component: () => import('@/views/agreement/myagreement.vue'),
          },
          {
            name: '编辑合同',
            path: '/agreement/edit/:id',
            perm: 'agreement.agreement.edit',
            meta: {module: 'agreement'},
            show:false,
            component: () => import('@/views/agreement/edit.vue'),
          },
        ]
    }
]