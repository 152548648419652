// 本地测试用
let sessionId = ''
export default {
    namespaced: true,
    state: {
        sessionId: localStorage.getItem('sessionId') || sessionId,
    },
    getters: {},
    mutations: {
        setSessionId(state:any, sessionId = '') {
            state.sessionId = sessionId
            localStorage.setItem('sessionId', sessionId)
        },
    }
}