export const roleApi = {
  save: {
      method: 'post',
      api: 'backend/role/save'
  },
  getList: {
    method: 'post',
    api: 'backend/role/list'
  },
  getAll: {
    method: 'post',
    api: 'backend/role/getAll'
  },
  get: {
    method: 'post',
    api: 'backend/role/get'
  },
  del: {
    method: 'post',
    api: 'backend/role/del'
  },
}