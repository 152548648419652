/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
import Layout from '@/views/layout/index.vue'
export default [
    {
        name: '回款管理',
        path: '/returnmoney/list',
        perm: '',
        meta: {module: 'returnmoney'},
        component: Layout,
        children: [
          {
            name: '回款管理',
            path: '/returnmoney/list',
            perm: 'returnmoney.list',
            meta: {module: 'returnmoney'},
            show:false,
            component: () => import('@/views/returnmoney/list.vue'),
          }
        ]
    }
]