export const productApi = {
  list: {
    method: 'post',
    api: 'backend/product/list'
  },
  save: {
      method: 'post',
      api: 'backend/product/save'
  },
  del: {
    method: 'post',
    api: 'backend/product/del'
  },
  get_cates_list: {
      method: 'post',
      api: 'backend/product/cates'
  },
  save_cates: {
      method: 'post',
      api: 'backend/product/save_cates'
  },
  del_cates: {
    method: 'post',
    api: 'backend/product/del_cates'
  },
  all: {
    method: 'post',
    api: 'backend/product/all'
  },
}