export default {
  title: '产品管理',
  name: 'product',
  path: '/product/list',
  show: true,
  icon: 'icon-zuocedaohang-shouye1',
  perm: '',
  zIndex: 6,
  children: {
    allclients: {
      title: '产品列表',
      name: 'list',
      path: '/product/list',
      icon: "icon-zuocedaohang-shouye1",
      perm:'product.product.list',
      show: true,
      children: {
        add: {
          title: '新增产品',
          name: 'addproduct',
          path: '/product/add/:id',
          show: false,
          perm:'product.product.add',
        },
        edit: {
          title: '编辑产品',
          name: 'editproduct',
          path: '/product/edit/:id',
          show: false,
          perm:'product.product.edit',
        },
      }
    },
    cates: {
      title: '产品分类',
      name: 'cates',
      path: '/product/cates',
      icon: "icon-zuocedaohang-shouye1",
      perm:'product.cates.list',
      show: true,
      children: {
        edit: {
          title: '编辑产品分类',
          name: 'editproductcates',
          path: '/product/cates/edit/:id',
          show: false,
          perm:'product.cates.edit',
        },
      }
    },
  }
}