export default {
    title: '合同管理',
    name: 'agreement',
    path: '/agreement/allagreement',
    show: true,
    icon: 'icon-zuocedaohang-shouye1',
    perm: '',
    zIndex: 4,
    children: {
        allagreement: {
        title: '全部合同',
        name: 'allagreement',
        path: '/agreement/allagreement',
        icon: "icon-zuocedaohang-shouye1",
        perm:'agreement.allagreement.list',
        show: true,
        children: {
          edit: {
            title: '编辑合同',
            name: 'editagreement',
            path: '/agreement/edit/:id',
            show: false,
            perm:'agreement.agreement.edit',
          },
        }
      },
      myagreement: {
        title: '我的合同',
        name: 'myagreement',
        path: '/agreement/myagreement',
        icon: "icon-zuocedaohang-shouye1",
        perm:'agreement.myagreement.list',
        show: true,
        children: {
          edit: {
            title: '编辑合同',
            name: 'editagreement',
            path: '/agreement/edit/:id',
            show: false,
            perm:'agreement.agreement.edit',
          },
        }
      },
    }
  }