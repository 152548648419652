export default {
    title: '商机管理',
    name: 'business',
    path: '/business/allbusiness',
    show: true,
    icon: 'icon-zuocedaohang-shouye1',
    perm: '',
    zIndex: 3,
    children: {
        allbusiness: {
        title: '全部商机',
        name: 'allbusiness',
        path: '/business/allbusiness',
        icon: "icon-zuocedaohang-shouye1",
        perm:'business.allbusiness.list',
        show: true,
        children: {
          edit: {
            title: '编辑商机',
            name: 'editbusiness',
            path: '/business/edit/:id',
            show: false,
            perm:'business.business.edit',
          },
        }
      },
      mybusiness: {
        title: '我的商机',
        name: 'mybusiness',
        path: '/business/mybusiness',
        icon: "icon-zuocedaohang-shouye1",
        perm:'business.mybusiness.list',
        show: true,
        children: {
          edit: {
            title: '编辑商机',
            name: 'editbusiness',
            path: '/business/edit/:id',
            show: false,
            perm:'business.business.edit',
          },
        }
      },
    }
  }