export default {
    title: '回款管理',
    name: 'returnmoney',
    path: '/returnmoney/list',
    show: true,
    icon: 'icon-zuocedaohang-shouye1',
    perm: '',
    zIndex: 5,
    children: {
      allclients: {
        title: '回款列表',
        name: 'list',
        path: '/returnmoney/list',
        icon: "icon-zuocedaohang-shouye1",
        perm:'returnmoney.list',
        show: true,
        children: {}
      }
    }
  }