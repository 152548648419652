/**
* 深拷贝
*/
export function deepCopy(obj1:any, obj2:any) {
  if (typeof obj1 != 'object') {
      return obj1;
  }
  if (obj2 === undefined) {
      if (Array.isArray(obj1)) {
          obj2 = []
      } else {
          obj2 = {}
      }
  }
  for (let i in obj1) {
    //   if (obj1.hasOwnProperty.call(i)) {
          if (Array.isArray(obj1[i])) {
              obj2[i] = [];
              deepCopy(obj1[i], obj2[i]);
          } else if (typeof obj1[i] === "function") {
              obj2[i] = obj1[i];
          } else if (obj1[i] instanceof Object) {
              obj2[i] = {};
              deepCopy(obj1[i], obj2[i]);
          } else {
              obj2[i] = obj1[i];
          }
    //   }
  }
  return obj2;
}

/**
* 如果target(也就是FirstOBJ[key])存在，
* 且是对象的话再去调用deepObjectMerge，
* 否则就是FirstOBJ[key]里面没这个对象，需要与SecondOBJ[key]合并
*/
export function deepMerge(FirstOBJ:any, SecondOBJ:any) { // 深度合并对象
  if (FirstOBJ === SecondOBJ) {
      return FirstOBJ
  }
  if (typeof SecondOBJ != 'object' ||SecondOBJ === null || typeof FirstOBJ != 'object') {
      return FirstOBJ
  }
  for (let key in SecondOBJ) {
      FirstOBJ[key] = FirstOBJ[key] && FirstOBJ[key].toString() === "[object Object]" ?
          deepMerge(FirstOBJ[key], SecondOBJ[key]) : (FirstOBJ[key] = SecondOBJ[key]);
  }
  return FirstOBJ;
}

/**
 * 深度比较两个对象是否相同
 * param {Object} oldData 
 * param {Object} newData 
 */
 export function deepCompare(oldData:any, newData:any) {
  // 类型为基本类型时,如果相同,则返回true
  if (oldData === newData) return true;
  if (isObject(oldData) && isObject(newData) && Object.keys(oldData).length === Object.keys(newData).length) {
      // 类型为对象并且元素个数相同
      // 遍历所有对象中所有属性,判断元素是否相同
      for (const key in oldData) {
        //   if (oldData.hasOwnProperty.call(key)) {
        //       if (!deepCompare(oldData[key], newData[key]))
        //           // 对象中具有不相同属性 返回false
        //           return false;
        //   }
        if (!deepCompare(oldData[key], newData[key])) {
                // 对象中具有不相同属性 返回false
                return false;
        }
      }
  } else if (isArray(oldData) && isArray(oldData) && oldData.length === newData.length) {
      // 类型为数组并且数组长度相同
      for (let i = 0, length = oldData.length; i < length; i++) {
          if (!deepCompare(oldData[i], newData[i]))
              // 如果数组元素中具有不相同元素,返回false
              return false;
      }
  } else {
      // 其它类型,均返回false
      return false;
  }
  // 走到这里,说明数组或者对象中所有元素都相同,返回true
  return true;
}

/**
 * 判断此对象是否是Object类型
 * param {Object} obj 
 */
 function isObject(obj:any) {
  return Object.prototype.toString.call(obj) === '[object Object]';
}
/**
* 判断此类型是否是Array类型
* param {Array} arr 
*/
function isArray(arr:any) {
  return Object.prototype.toString.call(arr) === '[object Array]';
}