export const departmentApi = {
  save: {
      method: 'post',
      api: 'backend/department/save'
  },
  getList: {
    method: 'post',
    api: 'backend/department/list'
  },
  get: {
    method: 'post',
    api: 'backend/department/get'
  },
  del: {
    method: 'post',
    api: 'backend/department/del'
  },
}