import $api from '@/api/index';
let cacheReqPerm:any = null;

export default {
    namespaced: true,
    state: {
        baseSetting: {
            logo: '',
            name: ''
        },
        user: {}
    },
    getters: {
        
    },
    mutations: {
        setUserInfo(state:any, user={}) {
            state.user = user
        },
        setRoutes(state:any, routes:any) {
            state.routes = routes
        },
        setBaseSetting(state:any, option:any) {
            state.baseSetting.logo = option.logo;
            state.baseSetting.name = option.name;
        },
        // 获取用户数据
        setInit(state:any, res:any) {
            state.user = res?.user;
            state.copyright = ""
        },
    },
    actions: {
        // 初始化数据、权限、用户、修改密码等链接
        getInit({
            dispatch
        }:any, { to, forceUpdate }:any) {
            if (!cacheReqPerm||!forceUpdate) { // 没有缓存或者强制刷新
                cacheReqPerm = new Promise((resolve, reject) => {
                    $api.accessoryApi.getInit()
                        .then((res:any) => {
                            resolve(res)
                        }).catch((err:any) => {
                            reject(err)
                        }).finally(() => {
                            cacheReqPerm = null;
                        })
                })
            }
            cacheReqPerm.then((res:any)=> dispatch('setInit',{response:res,toPath:to}))
            return cacheReqPerm
        },
        // 初始化设置
        setInit({commit}:any,{response,toPath}:any){
            if (!response.code) {
                toPath.user = response.data.user
                commit('setInit', response.data);
                commit('menu/refreshMenu', toPath, {
                    root: true
                })
                commit('setBaseSetting', response.data.settings || {});
            } else {
                commit('setInit', null);
            }
        },
        // 初始化数据、权限、用户、修改密码等链接
        getShopInit({
            dispatch
        }:any, { to, forceUpdate }:any) {
            if (!cacheReqPerm||!forceUpdate) { // 没有缓存或者强制刷新
                cacheReqPerm = new Promise((resolve, reject) => {
                    $api.accessoryApi.getShopInit()
                        .then((res: any) => {
                            resolve(res)
                        }).catch((err: any) => {
                            reject(err)
                        }).finally(() => {
                            cacheReqPerm = null;
                        })
                })
            }
           
            cacheReqPerm.then((res: any)=> dispatch('setInit',{response:res,toPath:to}))

            return cacheReqPerm
        },
    }
};
 