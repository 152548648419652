const routes = [{
  module: 'home',
  path: [
      '/workplace/statistics',
  ]
}]
let paths:any = []
let pathMap:any = {}
for (let val of routes) {
  paths = paths.concat(val.path)
  pathMap[val.module] = val.path;
}
export default (path: string) => {
  let tmp = path.split('/')[1];
  if (pathMap[tmp]) { 
    return  pathMap[tmp].concat(paths);//优先本模块内的页面
  }
  return paths;
}