export const userApi = {
  save: {
      method: 'post',
      api: 'backend/user/save'
  },
  changpwd: {
    method: 'post',
    api: 'backend/user/changpwd'
},
  getList: {
    method: 'post',
    api: 'backend/user/list'
  },
  all: {
    method: 'post',
    api: 'backend/user/all'
  },
  get: {
    method: 'post',
    api: 'backend/user/get'
  },
  del: {
    method: 'post',
    api: 'backend/user/del'
  },
  ImportUser: {
    method: 'post',
    api: 'backend/user/ImportUser'
  },
  exportExecl: {
    method: 'post',
    api: 'backend/user/exportExecl'
  },
}