/*
*  用于页面的一些宽度等基础配置
 */

export default {
  namespaced: true,
  state: {
      left: 300,
      routerEntering: false,
      isOpen: false, // 是否打开商城
  },
  mutations: {
      setRouterEntering(state:any, val:any) {
          state.routerEntering = val
      },
      updateLeft(store:any, payload:any) {
          store.content.left += payload;
      },
      setIsOpen(state:any, val:any) {
          state.isOpen = val;
      }
  },
  actions: {
      updateLeft({ commit }:any, payload:any) {
          commit('updateLeft', payload);
      }
  }
};
