/* eslint-disable */
import $api from '@/api'
import $store from '@/store'
export default {
    namespaced: true,
    state: {
        isLogin: null
    },
    mutations: {
        setLoginStatus(state: { isLogin: any }, isLogin: any) {
            state.isLogin = isLogin
        },
        setUserInfo(state: { user: any }, user: any) {
            state.user = user
        },
    },
    actions: {
        getLoginStatus({ state, commit }:any) { // 获取登录状态
            return new Promise((resolve) => {
                if (state.isLogin != null) {
                    resolve(state.isLogin)
                    return
                }
                $api.aloneApi.getLoginStatus().then((res:any) => {
                    if (res.code === 0) {
                        commit('setLoginStatus', res.data.loginStatus)
                        resolve(res.data.loginStatus)
                    }else{
                        resolve(false)
                    }
                })
            })
        },
        initUser({commit}:any){  // 清空用户信息
            commit('setLoginStatus', false)
            commit('setUserInfo', {})
            localStorage.setItem('sessionId','')
            $store.commit('account/setSessionId','')
            $store.commit('config/setUserInfo', {})
        },
        loginOut({ dispatch }:any) {
            return new Promise<void>((resolve) => {
                $api.aloneApi.logout().then((res:any) => {
                    if (res.code == 0) {
                        dispatch('initUser')
                        resolve()
                    }
                })
            })
        }
    },
    getters: {
        setting: (state:any) => state.setting,
        registSetting: (state:any) => state.registSetting
    }

}