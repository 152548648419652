export const businessApi = {
    get_client: {
      method: 'post',
      api: 'backend/client/get_client'
    },
    my_business_list: {
      method: 'post',
      api: 'backend/business/my_business_list'
    },
    get_business : {
      method: 'post',
      api: 'backend/business/get_business'
    },
    save_business: {
      method: 'post',
      api: 'backend/business/save_business'
    },
    get_business_list: {
      method: 'post',
      api: 'backend/business/get_business_list'
    },
    del_business: {
      method: 'post',
      api: 'backend/business/del_business'
    },
    save_follow: {
      method: 'post',
      api: 'backend/business/save_follow'
    },
    get_follows_list: {
      method: 'post',
      api: 'backend/business/get_follows_list'
    },
  }