export const agreementApi = {
    get_client: {
      method: 'post',
      api: 'backend/client/get_client'
    },
    my_agreement_list: {
      method: 'post',
      api: 'backend/agreement/my_agreement_list'
    },
    get_agreement: {
      method: 'post',
      api: 'backend/agreement/get_agreement'
    },
    save_agreement: {
      method: 'post',
      api: 'backend/agreement/save_agreement'
    },
    get_agreement_list: {
      method: 'post',
      api: 'backend/agreement/get_agreement_list'
    },
    del_agreement: {
      method: 'post',
      api: 'backend/agreement/del_agreement'
    },
    save_follow: {
      method: 'post',
      api: 'backend/agreement/save_follow'
    },
    get_follows_list: {
      method: 'post',
      api: 'backend/agreement/get_follows_list'
    },
    save_return: {
      method: 'post',
      api: 'backend/agreement/save_return'
    },
    get_return_list: {
      method: 'post',
      api: 'backend/agreement/get_return_list'
    },
    delreturnlog: {
      method: 'post',
      api: 'backend/agreement/delreturnlog'
    },
    save_bill: {
      method: 'post',
      api: 'backend/agreement/save_bill'
    },
    get_bill_list: {
      method: 'post',
      api: 'backend/agreement/get_bill_list'
    },
    delbilllog: {
      method: 'post',
      api: 'backend/agreement/delbilllog'
    },
  }