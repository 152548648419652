export default {
  title: '工作台',
  name: 'workplace',
  path: '/workplace/statistics',
  show: true,
  icon: 'icon-zuocedaohang-shouye1',
  perm: '',
  zIndex: 1,
  children: {
    statistics: {
      title: '数据统计',
      name: 'statistics',
      path: '/workplace/statistics',
      icon: "icon-zuocedaohang-shouye1",
      perm:'',
      show: true,
      children: {},
    },
  }
}