/**
 * 实现类似axios的请求方法
 */
import axios from 'axios'
import qs from 'qs'
import flatter from '@/assets/js/flatter'
import getSessionId from './getSessionId'

// 解决跨域使用
let base_url = '/'

let http = axios.create({
    baseURL: base_url,
    withCredentials: true,
    transformRequest: [
        function(datas) {
            // 解决传递数组变成对象的问题
            let data:any = [],isbreak = 0
            if(datas){
                data = flatter(datas) //对象扁平化
                Object.keys(data).forEach((key) => {
                    if(key){
                        if (typeof data[key] === 'object') {
                            data[key] = JSON.stringify(data[key]) // 这里必须使用内置JSON对象转换
                        }
                    }else{
                        isbreak=1;
                    }
                })
                if(isbreak!=1){
                    data = qs.stringify(data) // 这里必须使用qs库进行转换
                }
                
            }
            if(typeof data === 'string'){
                return data
            }else{
                return datas
            }
        },
    ],
})

// 请求拦截
// 注意请求头中不能有null或undefined的存在，不然会报错
http.interceptors.request.use(
    (config:any) => {
        return new Promise((r) => {
            if (!config.url.includes("getSessionId")) {
                getSessionId().then((res:any) => {
                    config.headers["Session-Id"] = res;
                r(config);
              });
            } else {
              r(config);
            }
        })
    },
    (error) => {
        return Promise.reject(error)
    }
)

// 响应拦截
http.interceptors.response.use(
    (response) => {
        // if (response.headers['content-type'].indexOf('text/html') > -1) {
        //     window.location.replace(settings.base_url)
        // }
        // 返回进入拦截成功
        return Promise.resolve(response.data)
    },
    function(error) {
        // 返回进入拦截失败
        return Promise.reject(error)
    }
)

export default http
