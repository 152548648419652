/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
import Layout from '@/views/layout/index.vue'
export default [
    {
        name: '数据中心',
        path: '/dataCenter/statistics',
        perm: '',
        meta: {module: 'dataCenter'},
        component: Layout,
        children: [
           {
             name: '数据统计',
             path: '/dataCenter/statistics',
             perm: 'dataCenter.statistics',
             meta: {module: 'dataCenter'},
             show:false,
             component: () => import('@/views/dataCenter/statistics.vue'),
           },
           {
             name: '数据报告',
             path: '/dataCenter/report',
             perm: 'dataCenter.report',
             meta: {module: 'dataCenter'},
             show:false,
             component: () => import('@/views/dataCenter/report.vue'),
           },
        ]
    }
]