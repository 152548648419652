/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
 import Layout from '@/views/layout/index.vue'
 export default [
     {
         name: '安全设置',
         path: '/website/operationLog',
         perm: '',
         meta: {module: 'website'},
         component: Layout,
         children: [
            {
              name: '操作日志',
              path: '/website/operationLog',
              perm: 'website.operationLog',
              meta: {module: 'website'},
              show:true,
              component: () => import('@/views/website/operationLog.vue'),
            }
         ]
     }
 ]