export default {
  title: '客户管理',
  name: 'client',
  path: '/client/allclients',
  show: true,
  icon: 'icon-zuocedaohang-shouye1',
  perm: '',
  zIndex: 2,
  children: {
    allclients: {
      title: '全部客户',
      name: 'allclients',
      path: '/client/allclients',
      icon: "icon-zuocedaohang-shouye1",
      perm:'client.allclients.list',
      show: true,
      children: {
        edit: {
          title: '编辑角色',
          name: 'editclient',
          path: '/client/client/edit/:id',
          show: false,
          perm:'client.client.edit',
        },
      }
    },
    myclients: {
      title: '我的客户',
      name: 'myclients',
      path: '/client/myclients',
      icon: "icon-zuocedaohang-shouye1",
      perm:'client.myclients.list',
      show: true,
      children: {
        edit: {
          title: '编辑角色',
          name: 'editclient',
          path: '/client/client/edit/:id',
          show: false,
          perm:'client.client.edit',
        },
      }
    },
  }
}