/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
 import Layout from '@/views/layout/index.vue'
 export default [
     {
         name: '权限系统',
         path: '/upms',
         perm: '',
         meta: {module: 'upms'},
         component: Layout,
         children: [
          {
            name: '公司列表',
            path: '/upms/company',
            perm: 'upms.company',
            meta: {module: 'upms'},
            show:false,
            component: () => import('@/views/upms/company/index.vue'),
          },
            {
              name: '部门列表',
              path: '/upms/department',
              perm: 'upms.department',
              meta: {module: 'upms'},
              show:false,
              component: () => import('@/views/upms/department/index.vue'),
            },
            {
              name: '角色列表',
              path: '/upms/role',
              perm: 'upms.role',
              meta: {module: 'upms'},
              show:false,
              component: () => import('@/views/upms/role/index.vue'),
            },
            {
              name: '新增角色',
              path: '/upms/role/add',
              perm: 'upms.role.add',
              meta: {module: 'upms'},
              show:false,
              component: () => import('@/views/upms/role/add.vue'),
            },
            {
              name: '编辑角色',
              path: '/upms/role/edit/:id',
              perm: 'upms.role.edit',
              meta: {module: 'upms'},
              show:false,
              component: () => import('@/views/upms/role/edit.vue'),
            },
            {
             name: '管理员账户',
             path: '/upms/admin',
             perm: 'upms.admin',
             meta: {module: 'upms'},
             show:false,
             component: () => import('@/views/upms/admin/index.vue'),
            }
         ]
     }
 ]