import { createStore } from 'vuex'

let paths = require.context('./', false, /\/.*\.ts$/);//获取当前全部路由
let modules:any = {};//各个模块的集合
let moduleName:any = [];

paths.keys().map(item => {//获取本级目录下的全部配置参数
  if (item != './index.ts') {
      moduleName.push(item.replace(/(\.\/|\.ts)/g, ''));
      return paths(item).default;
  }
  return '';
}).filter(item => item).forEach((item, index) => {
  modules[moduleName[index]] = item;
});

export default createStore({
  state: {
    account:{
      sessionId:'',
    },
    requestInfo: {
      error: 0,
      message: ''
    },
    curRoute: {
        path: '',
        query: {}
    },
    config:{
      user:{
        id:'',
        perms:[]
      },
      baseSetting:{}
    }
  },
  mutations: {
    setRequestInfo(state, info) {
      state.requestInfo = info;
    },
    setCurRoute(state, route) {
        state.curRoute = route;
    }
  },
  actions: {
  },
  getters: {
    getRequestInfo(state) {
        return {
            routeInfo: state.curRoute,
            ...state.requestInfo
        }
    }
  },
  modules: {
    ...modules,//文件名即为模块名
  }
})
