export const clientsApi = {
  list: {
    method: 'post',
    api: 'backend/client/list'
  },
  get_client: {
    method: 'post',
    api: 'backend/client/get_client'
  },
  get_my_clients_list: {
    method: 'post',
    api: 'backend/client/my_clients_list'
  },
  save_my_clients: {
    method: 'post',
    api: 'backend/client/save_my_clients'
  },
  del_my_clients: {
    method: 'post',
    api: 'backend/client/del_my_clients'
  },
  save_contacts: {
    method: 'post',
    api: 'backend/client/save_contacts'
  },
  get_contacts_list: {
    method: 'post',
    api: 'backend/client/get_contacts_list'
  },
  save_follow: {
    method: 'post',
    api: 'backend/client/save_follow'
  },
  get_follows_list: {
    method: 'post',
    api: 'backend/client/get_follows_list'
  },
  del_contacts: {
    method: 'post',
    api: 'backend/client/del_contacts'
  },
  save_business: {
    method: 'post',
    api: 'backend/client/save_business'
  },
  get_business_list: {
    method: 'post',
    api: 'backend/client/get_business_list'
  },
  del_business: {
    method: 'post',
    api: 'backend/client/del_business'
  },
  save_agreement: {
    method: 'post',
    api: 'backend/client/save_agreement'
  },
  get_agreement_list: {
    method: 'post',
    api: 'backend/client/get_agreement_list'
  },
}