/**
 * 本目录下的其他js文件导出的对象合并为这里的allApis，
 * 具体用法:
 *  this.$api.goodsApi.editGood({}).then().catch()
 */
/* eslint-disable */
 let allApis:any = {};//各个模块的路由配置集合
 let paths = require.context('@/', true, /.*\/api\/.*\.ts$/); //获取当前全部js文件
 paths.keys().forEach(item => {
     let def = paths(item);
     if (def) {
         Object.keys(def).forEach(item => {
             def[item] && (allApis[item] = def[item])
         })
 
     }
 });
 import request from './config/request'
 import interceptor from '../interceptors/index'
 
 /**
  * 请求前调用该方法，
  *
  * @param {Object} requestObj
  */
 const beforeRequest = (requestObj:any) => {
     let { requestParams, data, resolve, reject, requestInfo } = requestObj;
     if (typeof interceptor.afterRequest == 'function') {
         let resultObj:any = { next: resolve, result: null };
         request[requestParams.method](requestParams.api, data, requestInfo.settings).then((res:any) => {
             resultObj.next = () => { resolve(res) };
             resultObj.result = res;
             resultObj.requestInfo = requestInfo;
             // 请求后调用该方法
             interceptor.afterRequest(resultObj);
         }).catch((res:any) => {
             resultObj.next = () => { reject(res) };
             resultObj.result = res;
             resultObj.requestInfo = requestInfo;
             // 请求后调用该方法
             interceptor.afterRequest(resultObj);
         })
 
     } else {
         request[requestParams.method](requestParams.api, data).then((res:any) => {
             resolve(res)
         }).catch((res:any) => {
             reject(res)
         })
     }
 
 }
 /**
  * 自定义接口请求方法
  * 最终调用者使用方法为
  * apiModuleName.apiName(data).then().catch()
  * requestId 只是一个请求的标识，没用其他用处，会在afterRequest的返回对象中返回；主要用来区分请求的来源
  */
 const axios = function (this:any) {
     let that = this;
     //获取key值数组
     let keys = Object.keys(allApis);
     //遍历allApis一级属性
     keys.forEach(item => {
         //降属性值付给this
         that[item] = {};
         //一整个模块的全部api信息
         let childApiObject = allApis[item];
         //获取二级属性
         let childApiObjectKeys = Object.keys(childApiObject);
         //遍历二级属性
         childApiObjectKeys.forEach(key => {
             that[item][key] = (data:any, settings:any) => {
                 let requestParams = childApiObject[key] ? childApiObject[key] : {};
                 return new Promise((resolve, reject) => {
                     //获取请求参数对象
                     if (requestParams.method == 'get' || requestParams.method == 'post') {
                         if (typeof interceptor.beforeRequest == 'function') {
                             interceptor.beforeRequest({
                                 next: () => {
                                     beforeRequest({
                                         requestParams, data, resolve, reject, requestInfo: {
                                             moduleName: item,
                                             apiName: key,
                                             data: requestParams,
                                             settings
                                         }
                                     });
                                 },
                                 requestParams,
                                 data
                             })
                         } else {
                             beforeRequest({
                                 requestParams, data, resolve, reject, requestInfo: {
                                     moduleName: item,
                                     apiName: key,
                                     data: requestParams,
                                     settings
                                 }
                             });
                         }
 
                     } else {
                         reject(new Error('请求方式有误'))
                     }
 
                 })
             }
         })
 
     })
 }
 export default new (axios as any)();
 