/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
import Layout from '@/views/layout/index.vue'
export default [
    {
        name: '客户管理',
        path: '/client/allclients',
        perm: '',
        meta: {module: 'client'},
        component: Layout,
        children: [
          {
            name: '全部客户',
            path: '/client/allclients',
            perm: 'client.allclients.list',
            meta: {module: 'client'},
            show:false,
            component: () => import('@/views/client/allclients.vue'),
          },
          {
          name: '我的客户',
          path: '/client/myclients',
          perm: 'client.myclients.list',
          meta: {module: 'client'},
          show:false,
          component: () => import('@/views/client/myclients.vue'),
          },
          {
            name: '编辑客户',
            path: '/client/client/edit/:id',
            perm: 'client.client.edit',
            meta: {module: 'client'},
            show:false,
            component: () => import('@/views/client/edit.vue'),
          },
        ]
    }
]